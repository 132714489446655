/* eslint-disable object-shorthand */
import './css/styles.scss';
import { Mousewheel, Navigation } from 'swiper/modules';

function InitBlock() {
	// swiper element
	const swiperEl = document.querySelector('.femp-timeline-block swiper-container');
	if (!swiperEl) return;

	const slides = swiperEl.querySelectorAll('.swiper-slide');
	if (!slides) return;

	// swiper parameters
	const swiperParams = {
		modules: [Mousewheel, Navigation],
		// array with CSS styles
		injectStyles: [
			`

	
	
		:host .swiper-horizontal {
			padding-bottom: 3rem;
			padding: 10rem 0 5rem 0;
			overflow: visible;
			
		}

		:host .swiper-horizontal .swiper-wrapper {
			position:relative;
		}
		:host .swiper-horizontal .swiper-wrapper::before {
			content: '';
			background: url(https://ag.5punkter.com/app/uploads/2024/01/tidslinje-bg.svg) no-repeat center center;
			background-size: contain;
			position: absolute;
			top: -35%;
			left: 90px;
		
			width: 1450px;
			height: 100%;
			transform: scale(1.7);
			
		}
		@media (max-width: 1486px) {
			:host .swiper-horizontal .swiper-wrapper {
				margin-top: 10rem;
				margin-bottom:0;
			}
			:host .swiper-horizontal {
				padding-bottom: 3rem;
		
			}
	
		}
		@media (max-width: 768px) {
			:host .swiper-horizontal .swiper-wrapper {
				margin-top: 1rem;
				margin-bottom:0;
			}
			:host .swiper-horizontal .swiper-wrapper::before {
				top: -35%;
				left:20px;
				transform: scale(1.5);
			}
		}

		@media (max-width: 433px) {
			:host .swiper-horizontal .swiper-wrapper::before {
				top: -35%;
				left: 560px;
				transform: scale(1.6) translateX(-328px);
			}
			
			
		}

		@media (max-width: 350px) {
			:host .swiper-horizontal .swiper-wrapper::before {
				top: -35%;
				left: 600px;
				transform: scale(1.8) translateX(-328px);
			}
			
			
		}
	
      `,
		],

		slidesPerView: 1,
		spaceBetween: 140,
		loop: false,
		mousewheel: {
			forceToAxis: true,
		},
		// adjust height

		centeredSlides: false,
		keyboard: true,
		autoPlay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		navigatiation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		freeMode: true,
		breakpoints: {
			433: {
				slidesPerView: 1.3,
				spaceBetween: 140,
				centeredSlides: true,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 170,
				centeredSlides: true,
			},
			1000: {
				slidesPerView: 3,
				spaceBetween: 170,
				centeredSlides: true,
			},
			1481: {
				slidesPerView: 3.5,
				spaceBetween: 170,
				centeredSlides: false,
			},
		},
	};

	// now we need to assign all parameters to Swiper element
	Object.assign(swiperEl, swiperParams);

	// and now initialize it
	swiperEl.initialize();
}
document.addEventListener('DOMContentLoaded', function () {
	InitBlock();
});
